import { Route } from "react-router-dom";
import RentManagerNucleusDashboard from "../pages/Integrations/rent_manager/RentManagerDashboard";
import RentManagerFailedInvites from "../pages/Integrations/rent_manager/RentManagerFailedInvites";

const RentManagerNucleusRoutes = () => {
  return (
    <>
      <Route
        path="/nucleus/integrations/rent-manager/dashboard"
        component={RentManagerNucleusDashboard}
      />
      <Route
        path="/nucleus/integrations/rent-manager/failed-invites"
        component={RentManagerFailedInvites}
      />
    </>
  );
};

export default RentManagerNucleusRoutes;
