import Div from "../../../../components/baseComponents/Div";
import Row from "../../../../components/baseComponents/Row";

import GeneralInfo from "./components/TenantGeneralInfo";
import PaymentInfoTools from "./components/TenantPaymentInfoTools";

import usePrepaymentFromParams from "../../../utils/usePrepaymentFromParams";
import prepaymentOverviewGeneralTabSections from "../../../../enums/prepaymentOverviewGeneralTabSections";

interface sectionProps {
  sectionType?: string;
}

const YardiPrepaymentTab = ({ sectionType }: sectionProps) => {
  const { prepayment } = usePrepaymentFromParams();

  return (
    <>
      <Row mt={{ default: 4 }} addSpace={true}>
        <Div width={{ default: 12 / 12 }}>
          <GeneralInfo
            generalInfo={{
              company_name: prepayment.general_info.company,
              yardi_tenant_id: prepayment.integration_info.tenant_id,
              company_code: prepayment.integration_info.company_code,
              building_id: prepayment.integration_info.building_id,
              unit_id: prepayment.integration_info.unit_id,
              yardi_db: prepayment.integration_info.yardi_db,
            }}
          />
        </Div>
      </Row>
      {/* Payment tools */}

      <Row mt={{ default: 4 }} addSpace={true}>
        <PaymentInfoTools
          prepaymentInfo={prepayment.payment_info}
          prepaymentUuid={prepayment.invite_info.prepayment_uuid}
          tenantId={prepayment.integration_info.tenant_id}
          isOpen={
            sectionType === prepaymentOverviewGeneralTabSections.PAYMENT_INFO
          }
        />
      </Row>
    </>
  );
};

export default YardiPrepaymentTab;
