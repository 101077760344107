import { useState } from "react";
import styled from "styled-components";

import { GenericObject } from "../../../../../components/global/ModelInterfaces";
import { currencyFormatter } from "../../../../../components/global/utils";
import Div from "../../../../../components/baseComponents/Div";
import Row from "../../../../../components/baseComponents/Row";
import Button from "../../../../../components/baseComponents/Button";
import ErrorMessage from "../../../../../components/baseComponents/ErrorMessage";
import DropdownCard from "../../../../../components/baseComponents/DropdownCard";
import CollapsibleJson from "../../../../../components/baseComponents/CollapsibleJson";

import { postDepositReceipt } from "../../../../utils/yardiUtils";
import SuccessMessage from "../../../../../components/baseComponents/SuccessMessage";
import Input from "../../../../../components/baseComponents/Input";
import ConfirmationModal from "../../../../../components/baseComponents/ConfirmationModal";

const StyledLabel = styled(Div)`
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

const StyledDangerHeader = styled(ErrorMessage)`
  font-size: ${(props) => props.theme.font_size.headlineXL};
`;

const ModalDiv = styled(Div)`
  font-size: ${(props) => props.theme.font_size.headlineXS};
  font-weight: ${(props) => props.theme.font_weight.semibold};
`;

interface PaymentInfoToolsProps {
  tenantId: string;
  prepaymentUuid: string;
  prepaymentInfo: {
    yardi_tenant_id: string;
    amount: number;
    funded_by: string;
  };
  isOpen: boolean;
}

const parseReceiptSuccess = (json: GenericObject) => {
  /**
   * Navigate through the JSON to find the "#text" field, that contains the receipt id.
   * Also, check if the message type is "Error" and consider it as an error.
   * Return the message if it is a success message, otherwise return null.
   **/
  try {
    const parsedMessage =
      json?.message?.["soap:Envelope"]?.["soap:Body"]
        ?.ImportReceipt_LoginResponse?.ImportReceipt_LoginResult?.Messages
        ?.Message?.["#text"];
    const messageType =
      json?.message?.["soap:Envelope"]?.["soap:Body"]
        ?.ImportReceipt_LoginResponse?.ImportReceipt_LoginResult?.Messages
        ?.Message?.["@messageType"];
    if (messageType === "Error") return null;

    return typeof parsedMessage === "string"
      ? messageType + ": " + parsedMessage
      : null;
  } catch (error) {
    return null;
  }
};

const PaymentInfoTools = ({
  prepaymentInfo,
  prepaymentUuid,
  tenantId,
  isOpen,
}: PaymentInfoToolsProps) => {
  /**
   * Allows the user to post a deposit receipt to Yardi.
   */
  const [postingReceipt, setPostingReceipt] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState<string>(
    prepaymentInfo.amount.toString()
  );
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<GenericObject | null>(null);
  const [postingReceiptResult, setPostingReceiptResult] =
    useState<GenericObject | null>(null);

  const postReceiptSuccess = (response: GenericObject) => {
    const postResult = parseReceiptSuccess(response); // Parse the response to get the receipt id
    if (postResult) {
      setSuccessMessage(postResult);
      setPostingReceiptResult(response.message);
    } else {
      setErrorMessage(response.toString());
      setErrorData({ error: response });
    } // Well-formatted response, but not the expected one
    setPostingReceipt(false);
  };

  const postReceiptError = (response: GenericObject) => {
    const defaultError = "An error occurred while posting the receipt.";
    if (response.data) {
      setErrorMessage(response.data?.message || defaultError); // Error message from the API
      setErrorData(response.data);
    } else {
      setErrorMessage(defaultError); // Default error message
      setErrorData({ error: response });
    }
    setPostingReceipt(false);
  };

  const sendReceiptToYardi = () => {
    /* Request API the receipt to be posted */
    setConfirmationOpen(false);
    setPostingReceipt(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    setErrorData(null);
    setPostingReceiptResult(null);
    postDepositReceipt(
      prepaymentUuid,
      receiptAmount,
      postReceiptSuccess,
      postReceiptError
    );
  };

  return (
    <DropdownCard title={"Yardi Tenant Payment Tools"} initiallyOpen={isOpen}>
      <Div width={{ default: 12 / 12 }}>
        <Row>
          <StyledLabel>Yardi Tenant ID:</StyledLabel>
          {tenantId}
        </Row>
        <Row>
          <StyledLabel>Amount:</StyledLabel>
          {currencyFormatter.format(prepaymentInfo.amount)}
        </Row>
        <Row>
          <StyledLabel>Funded By:</StyledLabel>
          {prepaymentInfo.funded_by}
        </Row>
        <Row>
          <StyledLabel>Prepayment UUID:</StyledLabel>
          {prepaymentUuid}
        </Row>

        <Row mt={{ default: 4 }}>
          <Div width={{ default: 3 / 12 }}>
            <Input
              value={receiptAmount}
              placeholderText={receiptAmount}
              onChange={(e) => setReceiptAmount(e.target.value)}
              label="Post the receipt amount"
            />
          </Div>
        </Row>
        <Row>
          <Div width={{ default: 3 / 12 }} mt={{ default: 1 }}>
            <Button
              text="Post Deposit Receipt to Yardi"
              onClick={() => setConfirmationOpen(true)}
              loading={postingReceipt}
            />
          </Div>
        </Row>
        <Row mt={{ default: 4 }}>
          <Div width={{ default: 12 / 12 }}>
            {successMessage && (
              <>
                <SuccessMessage>{successMessage}</SuccessMessage>
                <CollapsibleJson
                  title="Receipt Posting Result"
                  json={postingReceiptResult || {}}
                  type="success"
                  isOpen={false}
                  formatted={true}
                  collapsible={false}
                />
              </>
            )}
            {errorMessage && (
              <>
                {" "}
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <Row mt={{ default: 4 }}>
                  <CollapsibleJson
                    title="Details"
                    type="success"
                    json={errorData || {}}
                    isOpen={false}
                    formatted={true}
                    collapsible={false}
                  />
                </Row>
              </>
            )}
          </Div>
        </Row>
      </Div>
      <Div width={{ default: 2 / 12 }} justifyContent="center">
        <ConfirmationModal
          confirmationOpen={confirmationOpen}
          onClose={() => {
            setConfirmationOpen(false);
          }}
          onConfirm={sendReceiptToYardi}
          buttonType="danger"
          message=""
          sizeVariant="small"
        >
          <Row justifyContent="center">
            <StyledDangerHeader>! DANGER !</StyledDangerHeader>
          </Row>
          <Row mt={{ default: 4 }} justifyContent="center">
            <ModalDiv width={{ default: 10 / 12 }} alignItems="center">
              You are about to post the receipt to the Yardi ledger.
            </ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 4 }}
              alignItems="center"
            >{`Tenant ID = ${tenantId}`}</ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 3 }}
              alignItems="center"
            >{`Amount = ${receiptAmount}`}</ModalDiv>
            <ModalDiv
              width={{ default: 10 / 12 }}
              mt={{ default: 4 }}
              alignItems="center"
            >
              Please double check before proceeding. This action cannot be
              undone!
            </ModalDiv>
          </Row>
        </ConfirmationModal>
      </Div>
    </DropdownCard>
  );
};

export default PaymentInfoTools;
